// Import Highway
import Highway from '@dogstudio/highway';

 // Import Transitions
import Fade from '../util/transitions/fade';

// Import Renderers
import DefaultRenderer from '../util/renderer/default-renderer';
import UIkit from 'uikit';
import Map from '../routes/map';

import '../routes/ajax';

$('.uk-slider-nav a').attr('target','_self');

export default {
  init() {
    // CONTENT SWItCHER MOBILE
    UIkit.util.on('.vy_contentswitcher_content', 'show', function (e) {
      var button_text = $(this).parents('.vy_contentswitcher').find('.vy_contentswitcher_dropdown_button_text');
      button_text.text($(e.target).data('title'));
    });
    $(document).on('click', '.vy_contentswitcher_dropdown_links_item_link', function(e) {
      e.preventDefault();
      var link = $(this);
      var parentItem = link.closest('.vy_contentswitcher_dropdown_links_item');
      var position = parentItem.index();
      var contentswitcher = parentItem.closest('.vy_contentswitcher');
      var switcher = contentswitcher.find('.vy_contentswitcher_tabs');

      // Use UIkit switcher API to switch to the corresponding tab
      UIkit.switcher(switcher).show(position);
    });
    // MAP
    Map.init();
    if ( ! String.prototype.getDecimals ) {
      String.prototype.getDecimals = function() {
        var num = this,
          match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if ( ! match ) {
          return 0;
        }
        return Math.max( 0, ( match[1] ? match[1].length : 0 ) - ( match[2] ? +match[2] : 0 ) );
      }
    }

    // if ($('.cmplz-links.cmplz-documents .cmplz-link.cookie-statement').length) {
    //   var item = $('.cmplz-links.cmplz-documents .cmplz-link.cookie-statement');
    //   var href = $(item).attr('href');
    //   var text = $(item).text();
    //   $('.vy_footer_copyright_wrapper').append(' - <a class="vy_footer_copyright_cookies uk-link-text" href="' + href + '">' + text + '</a>');
    // }
    if($('#form_formulairevoeuxdesympathie').length){
      $('.message_propose select').change(function(){
        // ajax_populate_message(1);

        var value = $(this).val();
        var changed = false;

        $.each(populate_message.sympathie, function(index, item) {
          // do something with `item` (or `this` is also `item` if you like)

          if(index.toString() === value){
            $('.message_sympathie textarea').val( item.message );
            changed = true;
          }
        });

        if(!changed) $('.message_sympathie textarea').val( "" );

      });
    }


    // Quantity "plus" and "minus" buttons
    $( document.body ).on( 'click', '.plus, .minus', function() {

      var $qty        = $( this ).closest( '.quantity' ).find( '.qty'),
        currentVal  = parseFloat( $qty.val() ),
        max         = parseFloat( $qty.attr( 'max' ) ),
        min         = parseFloat( $qty.attr( 'min' ) ),
        step        = $qty.attr( 'step' );
      // Format values
      if ( ! currentVal || currentVal === '' || currentVal === 'NaN' ) currentVal = 0;
      if ( max === '' || max === 'NaN' ) max = '';
      if ( min === '' || min === 'NaN' ) min = 0;
      if ( step === 'any' || step === '' || step === undefined || parseFloat( step ) === 'NaN' ) step = 1;

      // Change the value
      if ( $( this ).is( '.plus' ) ) {
        if ( max && ( currentVal >= max ) ) {
          $qty.val( max );
        } else {
          $qty.val( ( currentVal + parseFloat( step )).toFixed( step.getDecimals() ) );
        }
      } else {
        if ( min && ( currentVal <= min ) ) {
          $qty.val( min );
        } else if ( currentVal > 0 ) {
          $qty.val( ( currentVal - parseFloat( step )).toFixed( step.getDecimals() ) );
        }
      }

      // Trigger change event
      $qty.trigger( 'change' );
    });

    $(document).ready(function(){
      if ($("section").hasClass("vy_voeux_print")){
        // $('#printf')[0].contentWindow.print()
        window.print()
      return false;
      }
    });

    $(document).on('mouseenter', '.vy_link_cover', function () {
      if ($(this).parents('.vy_link_cover_wrapper').length) {
        var $parent = $(this).parents('.vy_link_cover_wrapper');
      }else{
        var $parent = $(this).parent();
      }
      $parent.find(".uk-button").addClass('uk-active');
      $parent.find(".uk-link-heading").addClass('uk-active');
      $parent.addClass('uk-transition-active uk-active');
    }).on('mouseleave', '.vy_link_cover', function () {
      if ($(this).parents('.vy_link_cover_wrapper').length) {
        var $parent = $(this).parents('.vy_link_cover_wrapper');
      }else{
        var $parent = $(this).parent();
      }
      $parent.find(".uk-button").removeClass('uk-active');
      $parent.find(".uk-link-heading").removeClass('uk-active');
      $parent.removeClass('uk-transition-active uk-active');
    }).on('focus', '.vy_link_cover', function () {
      if ($(this).parents('.vy_link_cover_wrapper').length) {
        var $parent = $(this).parents('.vy_link_cover_wrapper');
      }else{
        var $parent = $(this).parent();
      }
      $parent.find(".uk-button").addClass('uk-active');
      $parent.find(".uk-link-heading").addClass('uk-active');
      $parent.addClass('uk-transition-active uk-active');
    }).on('blur', '.vy_link_cover', function () {
      if ($(this).parents('.vy_link_cover_wrapper').length) {
        var $parent = $(this).parents('.vy_link_cover_wrapper');
      }else{
        var $parent = $(this).parent();
      }
      $parent.find(".uk-button").removeClass('uk-active');
      $parent.find(".uk-link-heading").removeClass('uk-active');
      $parent.removeClass('uk-transition-active uk-active');
    });
    $( ".uk-offcanvas-bar a" ).unbind('click').on( "click",function(){
      //.uk-offcanvas-bar
      UIkit.offcanvas('.uk-offcanvas.uk-open').hide();
    });

    $(function() {
      // trigger banner video play
      if ($('.vy_banner_video video').length) {
        $('.vy_banner_video video').each(function() {
          console.log('each video');

          var video = $(this).get(0);
          if (video.paused) {
            video.play().catch(function(error) {
              console.log('Video playback failed: ', error);
            });
          }
        });
      }

      $('.fb-share').click(function(e) {
        e.preventDefault();
        window.open($(this).attr('href'), 'fbShareWindow',
          'height=450, width=550, top=' + ($(window).height() / 2 - 275) +
          ', left=' + ($(window).width() / 2 - 225) +
          ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        return false;
      });

      if ( $( "#vy_header_global_alert" ).length ) {
        var globalAlertSeen = parseInt(sessionStorage.getItem("globalAlertSeen"));
        var global_alert = $('#vy_header_global_alert');

        if(globalAlertSeen != true){
          global_alert.removeClass('uk-hidden');
        }else{

          UIkit.alert(global_alert).close();
        }
        $('.vy_header_global_alert_close').click(function(){
          if (window.sessionStorage) {
            var dropselvalue = 1;
            sessionStorage.setItem("globalAlertSeen", dropselvalue);
            globalAlertSeen = parseInt(sessionStorage.getItem("globalAlertSeen"));
          }
        });
      }
      if ( $( "#vy_popup" ).length ) {
        var now = new Date()
        var globalPopupSeenStr = sessionStorage.getItem("globalPopupSeen");

        var global_popup = $('#vy_popup');

        if(globalPopupSeenStr){
          var globalPopupSeen = JSON.parse(globalPopupSeenStr);
          var globalPopupSeenDate = new Date(globalPopupSeen.expiry);
          if (now.setDate(now.getDate()) > globalPopupSeenDate.setDate(globalPopupSeenDate.getDate())) {
            sessionStorage.removeItem("globalPopupSeen");
            UIkit.modal(global_popup).show();
          }
        }else{
          UIkit.modal(global_popup).show();
        }

        $('.vy_popup_close').click(function(){
          if (window.sessionStorage) {
            var date = new Date();
            date.setDate(date.getDate() + 7);
            var dropselvalue = 1;
            const item = {
              value: dropselvalue,
              expiry: date,
            }
            sessionStorage.setItem("globalPopupSeen", JSON.stringify(item));
            globalPopupSeen = parseInt(sessionStorage.getItem("globalPopupSeen"));
          }
        });
      }


      UIkit.util.on('.vy_navbar_ecom_search_drop', 'show', function () {
        $('.vy_main_content').toggleClass('uk-active');
      });
      UIkit.util.on('.vy_navbar_ecom_search_drop', 'hide', function () {
        $('.vy_main_content').toggleClass('uk-active');
      });

      if($('.vy_banner_slideshow').length){
        UIkit.util.on('.vy_banner_slideshow > .uk-slideshow', 'itemshow', function (e) {
          if($(e.target).find('.vy_banner_slideshow_content_wrapper').hasClass('uk-light')){
            $('.vy_banner_slideshow_dotnav').addClass('uk-light');
          }else{
            $('.vy_banner_slideshow_dotnav').removeClass('uk-light');
          }
        });
      }
      if($('.vy_banner_hero_item').length){
        UIkit.util.on('.vy_banner_hero > .uk-slider', 'itemshow', function (e) {
          if($(e.target).hasClass('uk-light')){
            $('.vy_banner_hero_dotnav').addClass('uk-light');
          }else{
            $('.vy_banner_hero_dotnav').removeClass('uk-light');
          }
        });
      }
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

  },
};

// Get IE or Edge browser version
var version = detectIE();
var using_IE = false;

if (version === false) {
  console.log('IE/Edge');
} else if (version >= 12) {
  console.log('Edge ' + version);
} else {
  console.log('IE ' + version);
  using_IE = true;

  setTimeout(function(){
    $('.home .vy_loading').fadeOut();
  }, 4000);
}
// add details to debug result
console.log (window.navigator.userAgent);

// Call Highway.Core once.
if($('.transition').length != 0  && using_IE == false){
  const H = new Highway.Core({
    transitions: {
      default: Fade
    },
    renderers: {
      name: DefaultRenderer,
      //home: TimeOut
    }
  });
  H.on('NAVIGATE_END', ({ location }) => {
    // Check Anchor
    if (location.anchor) {
      // Get element
      const el = document.querySelector(location.anchor);

      if (el) {
        // Scroll to element
        window.scrollTo(el.offsetLeft, el.offsetTop);

      }
    }
  });
}


function detectIE() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}
